const youtubeModal = document.getElementById('js-youtube-player')
const youtubeModalActiveClass = 'youtube-modal--active'
const youtubeFrame = document.getElementById('js-youtube-frame')

const youtubeModalBackdrop = document.getElementById(
   'js-youtube-player-backdrop'
)
const youtubeModalBackdropActiveClass = 'youtube-modal__backdrop--active'

const youtubeModalTriggers = document.getElementsByClassName(
   'js-youtube-player-trigger'
)

Array.from(youtubeModalTriggers).forEach(trigger => {
   trigger.addEventListener('click', () => {
      openYoutubeModal(trigger.getAttribute('data-youtube-id'))
   })
})

if (youtubeModalBackdrop) {
   youtubeModalBackdrop.addEventListener('click', () => {
      closeYoutubeModal()
   })
}

function openYoutubeModal(id) {
   youtubeFrame.setAttribute('src', 'https://www.youtube.com/embed/' + id)
   youtubeModal.classList.add(youtubeModalActiveClass)
   youtubeModalBackdrop.classList.add(youtubeModalBackdropActiveClass)
}

function closeYoutubeModal() {
   youtubeModal.classList.remove(youtubeModalActiveClass)
   youtubeModalBackdrop.classList.remove(youtubeModalBackdropActiveClass)
   youtubeFrame.setAttribute('src', '')
}
