/*
    Init calendar
*/


var el = document.getElementById("js-calendar-options");
var app = new Vue({
   el,
   data() {
      return {
         active: false,
         type: "Alle",
         isMounted: false
      }
   },
   computed: {
      getUrl() {
         if(!this.isMounted){
            return "";
         }
         const calendarId = this.$refs.calendarInput.getAttribute("data-calendar-id");
         return window.location.origin + "/Umbraco/Api/Calendar/Feed?calendarId="+ calendarId +"&type="+ this.type;
      }
   },
   mounted() {
      console.log(this.$refs.calendarInput);
      this.isMounted = true;
   },
   methods: {
      copyCalendarUrl() {
         var copyText = this.$refs.calendarInput;
         copyText.select()
         copyText.setSelectionRange(0, 99999)
         document.execCommand('copy')
      
         if (this.active) {
            this.active = false;
         }
      
         setTimeout(() => {
            this.active = true;
         })
      }
   }
});


document.addEventListener('DOMContentLoaded', function() {
   var calendarEl = document.getElementById('calendar')

   if (calendarEl) {
      var filter = document.getElementById('calendar-filter')

      let contentHeight = window.innerHeight
      if (contentHeight > 1000) {
         contentHeight = 1000
      }

      var calendar = new FullCalendar.Calendar(calendarEl, {
         events: dates,
         locale: 'da',
         contentHeight,
         defaultView: window.innerWidth >= 985 ? 'month' : 'upcomming',
         header: {
            left: 'prev,next today',
            center: 'title',
            right: 'month,upcomming',
         },
         eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit',
         },
         displayEventEnd: true,
         views: {
            upcomming: {
               visibleRange: function(currentDate) {
                  console.log(new Date().setMonth(new Date().getMonth() + 1))
               },
               type: 'list',
               duration: { days: 30 },
               buttonText: 'Kommende',
            },
         },
      })

      calendar.render()
   }
})
