const images = document.getElementsByClassName('js-image')

var controller = new ScrollMagic.Controller()

if (window.innerWidth >= 985) {
   var scene = new ScrollMagic.Scene({
      triggerElement: 'js-row-animate',
      duration: '150%',
      triggerHook: 0,
   })
      .setTween('.js-row-animate .js-column-1', 1, {
         css: { transform: 'translateY(400px)' },
         ease: Linear.easeInOut,
      })
      .addTo(controller)

   var scene = new ScrollMagic.Scene({
      triggerElement: 'js-row-animate',
      duration: '100%',
      triggerHook: 0,
   })
      .setTween('.js-row-animate .js-column-2', 1, {
         css: { transform: 'translateY(200px)' },
         ease: Linear.easeInOut,
      })
      .addTo(controller)

   var scene = new ScrollMagic.Scene({
      triggerElement: 'js-spacerline',
      duration: '100%',
      offset: 500,
      triggerHook: 0.5,
   })
      .setTween('.js-spacerline', 1, {
         css: { maxHeight: '100%' },
         ease: Linear.easeInOut,
      })
      .addTo(controller)
} else {
   var scene = new ScrollMagic.Scene({
      triggerElement: 'js-spacerline',
      duration: '100%',
      offset: 300,
      triggerHook: 0,
   })
      .setTween('.js-spacerline', 1, {
         css: { maxHeight: '20%' },
         ease: Linear.easeInOut,
      })
      .addTo(controller)
}

const faders = document.getElementsByClassName('js-fade')
Array.from(faders).forEach(fader => {
   const content = fader.getElementsByClassName('container')[0]

   var scene = new ScrollMagic.Scene({
      triggerElement: fader,
      duration: 500,
      triggerHook: 1,
   })
      .setTween(content, 1, {
         css: { transform: 'translateY(0)' },
         ease: Linear.easeInOut,
      })
      .addTo(controller)
})
