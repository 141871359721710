const progressBar = document.getElementById('js-progress-bar')

if (progressBar != null) {
   function getScrollPercent() {
      var h = document.documentElement,
         b = document.body,
         st = 'scrollTop',
         sh = 'scrollHeight'
      return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
   }

   window.addEventListener('scroll', function() {
      progressBar.style.width = getScrollPercent() + '%'
   })
}
