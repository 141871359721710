const client = '44edae279a66629bfd1a5eb4e0459ee0'

if (document.getElementsByClassName("js-sermons").length) {
   var app = new Vue({
      el: '.js-sermons',
      data() {
         return {
            sermons: [],
            count: 5,
            iframe: null,
            loadingList: true,
            loadingItem: true,
            activeIndex: null,
         }
      },
      methods: {
         getSermons() {
            this.loadingItem = true
            this.loadingList = true
            fetch(
               'https://api.soundcloud.com/users/92870841/tracks.json?client_id=' +
               client,
               {
                  method: 'GET',
                  cache: 'force-cache',
               }
            ).then(response => {
               response.json().then(data => {
                  this.sermons = data
                  this.loadingList = false
                  this.setIframe(0, false)
               })
            })
         },
         loaded() {
            this.loadingItem = false
         },
         setIframe(index, play) {
            this.loadingItem = true
            this.activeIndex = index
            const id = this.sermons[index].id
            this.iframe =
               'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/' +
               id +
               '&color=%23fb611d&auto_play=' +
               play +
               '&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=true'
         },
         showMore() {
            this.count = this.count + 5
         },
      },
      mounted() {
         this.getSermons()
      },
      filters: {
         duration: function (value) {
            const minutes = value / 1000 / 60
            return Math.ceil(minutes) + ' min.'
         },
         date: function (value) {
            const date = new Date(value)
            const day = date.getDate()
            const month = date.getMonth()
            const year = date.getFullYear()

            return day + '/' + month + ' ' + year
         },
      },
   });
}
