'use strict'

//Register Service Worker
if ('serviceWorker' in navigator) {
   window.addEventListener('load', function() {
      navigator.serviceWorker.register('/sw.js').then(
         function(registration) {
            // Registration was successful
            console.log(
               'ServiceWorker registration successful with scope: ',
               registration.scope
            )

            var swRegistration = registration
         },
         function(err) {
            // registration failed :(
            console.log('ServiceWorker registration failed: ', err)
         }
      )
   })
}
