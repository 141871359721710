const messages = {
   _default: field => `${field} er ikke gyldig.`,
   date_format: (field, [format]) =>
      `${field} skal være i formatet: ${format}.`,
   decimal: (field, [decimals = '*'] = []) =>
      `${field} skal være numerisk og må maksimalt indeholde${
         !decimals || decimals === '*' ? '' : ' ' + decimals
      } decimaler.`,
   digits: (field, [length]) => `${field} skal være et tal på ${length} cifre.`,
   email: field => `${field} skal være en gyldig email.`,
   min: (field, [length]) => `${field} skal minimum være ${length} karakterer.`,
   numeric: field => `${field} skal være numerisk.`,
   regex: field => `${field} skal have et gyldigt format.`,
   required: field => `${field} skal udfyldes.`,
}

Vue.use(VeeValidate, {
   locale: 'da',
   classes: true,
   dictionary: {
      da: { messages },
   },
})

var app = new Vue({
   el: '#js-signup',
   data() {
      return {
         loading: false,
         message: null,
         step: 0,
         signup: {
            person: {
               status: 'single',
            },
            spouse: {},
            addSpouse: false,
            addChildren: false,
            children: [{}],
            motivation: '',
            attendingGroup: false,
            attendedCourse: false,
            givingRegular: false,
            acceptTerms: false,
         },
      }
   },
   methods: {
      goToStep(step) {
         if (step < this.step) {
            this.step = step
         } else {
            this.$validator.validate().then(valid => {
               if (!valid) {
                  console.log(this.errors)

                  var firstError = document.querySelector('.invalid')

                  var distance =
                     firstError.offsetTop -
                     firstError.scrollTop +
                     firstError.clientTop

                  window.scrollTo({
                     top: distance - 100,
                     behavior: 'smooth',
                  })
               } else {
                  window.scrollTo({
                     top: 0,
                     behavior: 'smooth',
                  })
                  this.step = step
               }
            })
         }
      },

      submit() {
         this.loading = true

         var xhr = new XMLHttpRequest()
         xhr.open('POST', window.location.href)
         xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
         xhr.onload = () => {
            this.loading = false
            this.step = 4
            this.message = xhr.response
         }
         xhr.onerror = () => {
            this.step = 4
            this.loading = false
            this.message = xhr.response
         }
         xhr.send(JSON.stringify(this.signup))
      },

      addChild() {
         this.signup.children.push({})
      },

      removeChild() {
         this.signup.children.pop()
      },
   },
   mounted() {
      var signupDataEl = document.getElementById('js-signup-data')

      if (signupDataEl) {
         this.signup = JSON.parse(signupDataEl.innerHTML)
      }

      if (document.getElementById('js-signup-disabled')) {
         window.print()
      }
   },
})
