const accordions = document.getElementsByClassName('js-accordion');

for (var i = 0; i < accordions.length; i++) {
   var el = accordions[i];

   console.log(el);

   var app = new Vue({
      el,
      data() {
         return {
            expanded: false
         }
      },
      methods: {
         toggle() {
            this.expanded = !this.expanded;
         },
         startTransition(el) {
            el.style.height = `${el.scrollHeight}px`;
         },
         endTransition(el) {
            el.style.height = '';
         }
      }
   });
}






