var app = new Vue({
   el: '.js-social',
   data() {
      return {
         loading: true,
         images: [],
      }
   },
   methods: {
      getImages() {
         this.loading = true

         fetch(
            'https://graph.instagram.com/1632068428/media?access_token=1632068428.1677ed0.d90aa8a6ee4e4f41916f186a744437a6&count=8',
            {
               method: 'GET',
            }
         ).then((response) => {
            response.json().then((data) => {
               this.images = data.data
               this.loading = false
            })
         })
      },
   },
   mounted() {
      // this.getImages()
   },
})
