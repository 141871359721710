const navigationTrigger = document.getElementById('js-offcanvas-trigger')
const navigationTriggerActiveClass = 'offcanvas__trigger--active'

const offcanvasNavigation = document.getElementById('js-offcanvas')
const offcanvasNavigationActiveClass = 'offcanvas--active'

const offcanvasBackdrop = document.getElementById('js-offcanvas-backdrop')
const offcanvasBackdropActiveClass = 'offcanvas__backdrop--active'

function openOffcanvas() {
   navigationTrigger.classList.add(navigationTriggerActiveClass)
   offcanvasNavigation.classList.add(offcanvasNavigationActiveClass)
   offcanvasBackdrop.classList.add(offcanvasBackdropActiveClass)
}

function closeOffcanvas() {
   navigationTrigger.classList.remove(navigationTriggerActiveClass)
   offcanvasNavigation.classList.remove(offcanvasNavigationActiveClass)
   offcanvasBackdrop.classList.remove(offcanvasBackdropActiveClass)
}

if (offcanvasBackdrop) {
   offcanvasBackdrop.addEventListener('click', () => {
      closeOffcanvas()
   })
}

if (navigationTrigger) {
   navigationTrigger.addEventListener('click', () => {
      if (
         offcanvasNavigation.classList.contains(offcanvasNavigationActiveClass)
      ) {
         closeOffcanvas()
      } else {
         openOffcanvas()
      }
   })
}

const navigationSubitemTriggers = document.getElementsByClassName(
   'js-navigation-subitem-trigger'
)

const navigationSubItemTriggerActiveClass = 'offcanvas__subitem--open'

Array.from(navigationSubitemTriggers).forEach(trigger => {
   trigger.addEventListener('click', () => {
      trigger.parentNode.classList.toggle(navigationSubItemTriggerActiveClass)
   })
})

let lastScrollPosition = 0
const navigation = document.getElementById('js-navigation')
if (navigation) {
   window.addEventListener('scroll', () => {
      const currentScrollPosition =
         window.pageYOffset || document.documentElement.scrollTop

      if (currentScrollPosition > 50) {
         navigation.classList.add('navigation--focus')
      } else {
         navigation.classList.remove('navigation--focus')
      }

      if (currentScrollPosition < 200) {
         navigation.classList.remove('navigation--hide')
         navigationTrigger.classList.remove('offcanvas__trigger--focus')
         return
      }

      if (currentScrollPosition < lastScrollPosition) {
         navigation.classList.remove('navigation--hide')
         navigationTrigger.classList.remove('offcanvas__trigger--focus')
      } else {
         navigation.classList.add('navigation--hide')
         navigationTrigger.classList.add('offcanvas__trigger--focus')
      }
      lastScrollPosition = currentScrollPosition
   })
}
